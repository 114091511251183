
$('.speaker .expand').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    $this.toggleClass('open');
    $this.next('.more-info').slideToggle();

    var isOpen = $this.hasClass('open');
    $this.find('span').text(isOpen ? 'Close' : 'Read more');
});