
$('.tease-company .expand').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var isOpen = $this.hasClass('open');

    $('.tease-company').find('.expand, .more-info').removeClass('open');

    if (!isOpen) {
        $this.toggleClass('open');
        $this.next('.more-info').toggleClass('open');
    }
});