$headerNav = $('header nav');

$('#menu-button').click(function(e) {
    e.preventDefault();

    showMenu();
});

$('#close-menu, header nav .bg').click(function(e) {
    e.preventDefault();

    closeMenu();
});

function showMenu() {
    $headerNav.addClass('show');

    setTimeout(function() {
        $headerNav.addClass('fade');
    }, 50);
}

function closeMenu() {
    $headerNav.removeClass('fade');

    setTimeout(function() {
        $headerNav.removeClass('show');
    }, 300);
}