let acc = document.querySelectorAll(".accordion");
let i;

if (acc.length > 0) {
    acc.forEach(item =>  {
        console.log(item.innerText);
        console.log(item.firstChild.nextElementSibling.innerText);
        item.addEventListener("click", function () {

            if(item.firstChild.nextElementSibling.innerText === "Close"){
                item.firstChild.nextElementSibling.innerText = "Read more"
            }else{
                item.firstChild.nextElementSibling.innerText = "Close"
            }

            this.classList.toggle("active");
            let panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    })
}
