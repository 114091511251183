$('.expertise-filter input').change(function(e) {
    var $parent = $(this).parents('ul');
    var $checked = $parent.find('input:checked');

    var areasOfExpertise = [];
    $checked.each(function(index, input) {
        areasOfExpertise.push(input.getAttribute('name'));
    });

    if (areasOfExpertise.length > 0) {
        $('.tease-company').removeClass('index-1').removeClass('index-2').removeClass('index-3').removeClass('index-4').hide();

        for (var i = 0; i < areasOfExpertise.length; i++) {
            var area = areasOfExpertise[i];
            $('.member.' + area).parents('.tease-company').addClass('index-' + (i + 1)).show();
        }
    } else {
        $('.tease-company').show();

        $('.members').each(function(index, memberList) {
            $(memberList).find('.tease-company').each(function(index, tease) {
                $(tease).addClass('index-' + (index + 1));
            })
        })
    }

});